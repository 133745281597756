import { PageProps, graphql } from "gatsby";
import { useLocation } from "@reach/router";

import { Container } from "@util/standard";
import Header from "@shared/header";
import HomeContent from "@shared/homeContent";
import { Query } from "@graphql-types";
import React from "react";
import SEO from "@shared/seo";
import { ContactForm } from "@global";


interface Props extends PageProps {
  data: Query;
  pageContext: any;
}

const PageTemplate = ({ data }: Props) => {
  const { pathname} = useLocation();
  if (data == null) return null;

  const { seo, blocks } = data.allSanityPageTemplate.nodes[0];
  const hasHero = blocks?.some(block => block?._type === "hero");
  const articleRegex = /^\/?articles\/.+$/;
  const isArticles = articleRegex.test(pathname);

  return (
    <div>
      <SEO seoData={seo} />
      <Header isSolidHeader={!hasHero} />
      <Container flexDirection="column">
        {blocks &&
          blocks.map(component => {
            if (!component) return null;
            return <HomeContent key={component._key} data={component} />;
          })}
        {isArticles && <ContactForm hideContactInfo />}
      </Container>
    </div>
  );
};

export default PageTemplate;

export const query = graphql`
  query PageQuery($slug: String) {
    allSanityPageTemplate(filter: { slug: { current: { eq: $slug } } }) {
      nodes {
        slug {
          current
        }
        seo {
          ...sanitySeo
        }
        blocks {
          ... on SanityColumnContent {
            ...sanityColumnContent
          }
          ... on SanityHero {
            ...sanityHero
          }
          ... on SanityServices {
            ...sanityServices
          }
          ... on SanityTestimonials {
            ...sanityTestimonials
          }
        }
      }
    }
  }
`;
