import React, { useState } from "react";
import styled from "styled-components";
import { navigate } from "gatsby";

import { useHeaderQuery } from "@api";
import { colors, MOBILE_BREAKPOINT } from "@util/constants";
import { Container } from "@util/standard";
import { Link, Image } from "@global";
import { useCheckScreenWidth, useEventListener } from "@util/hooks";

const HeadWrapper = styled.div<{ hasScrolled: boolean }>`
  width: 100%;
  position: fixed;
  background-color: ${colors.transparent};
  border-top: 6px solid ${colors.blue};
  z-index: 10;

  @keyframes fade-in {
    100% {
      background-color: ${colors.lightCascade};
    }
  }
  @keyframes fade-out {
    100% {
      background-color: ${colors.transparent};
    }
  }

  ${props =>
    props.hasScrolled ? `animation: fade-in 0.5s forwards;` : `animation: fade-out 0.5s forwards;`}
`;

const HeaderContainer = styled(Container)`
  margin: 10px 30px;
  justify-content: space-between;
  align-items: center;

  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    margin: 10px 20px;
  }
`;

const ImageWrapper = styled.div`
  width: 100%;
  cursor: pointer;
  max-width: 230px;

  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    width: 150px;
  }
`;

interface Props {
  isSolidHeader?: boolean;
}

const Header = ({ isSolidHeader }: Props) => {
  const { sanityNavigation } = useHeaderQuery();

  if (sanityNavigation == null) {
    return null;
  }

  const { links, logo, logoScrolled } = sanityNavigation;

  const [hasScrolled, setHasScrolled] = useState(false);
  const { isMobileWidth } = useCheckScreenWidth();

  const handleScroll = () => {
    const scrollDistance = document.body.scrollTop + document.documentElement.scrollTop;

    if (scrollDistance > 10) {
      setHasScrolled(true);
    } else {
      setHasScrolled(false);
    }
  };
  useEventListener("scroll", handleScroll);

  const Logo = () => {
    if ((isSolidHeader || hasScrolled) && logoScrolled) {
      return (
        <ImageWrapper onClick={() => navigate("/")}>
          <Image width="100%" imageData={logoScrolled.asset?.gatsbyImageData} />
        </ImageWrapper>
      );
    }

    return (
      <ImageWrapper onClick={() => navigate("/")}>
        <Image width="100%" imageData={logo?.asset?.gatsbyImageData} />
      </ImageWrapper>
    );
  };

  return (
    <HeadWrapper hasScrolled={hasScrolled}>
      <HeaderContainer>
        {logo && <Logo />}
        {links && (
          <Container>
            {links.map(link => {
              if (link == null) {
                return null;
              }

              if (isSolidHeader) {
                return (
                  <Link
                    padding={isMobileWidth ? "0 0 0 20px" : "0 0 0 50px"}
                    color="base"
                    key={link._key}
                    {...link}
                  />
                );
              }

              return (
                <Link
                  padding={isMobileWidth ? "0 0 0 20px" : "0 0 0 50px"}
                  color={hasScrolled ? "base" : "white"}
                  key={link._key}
                  {...link}
                />
              );
            })}
          </Container>
        )}
      </HeaderContainer>
    </HeadWrapper>
  );
};

export default Header;
