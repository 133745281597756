import React from "react";

import { Maybe, SanityColumnContentOrHeroOrServicesOrTestimonials } from "@graphql-types";
import { Hero, ColumnContent, Services, Testimonials } from "@global";
import { P } from "@util/standard";
import {
  isSanityHero,
  isSanityColumnContent,
  isSanityServices,
  isSanityTestimonials,
} from "@util/types";

interface Props {
  data: Maybe<SanityColumnContentOrHeroOrServicesOrTestimonials>;
}

const HomeContent = ({ data }: Props) => {
  if (data == null) {
    return null;
  }

  if (isSanityHero(data)) {
    return <Hero height="90vh" heroData={data} />;
  }

  if (isSanityColumnContent(data)) {
    return <ColumnContent data={data} />;
  }

  if (isSanityServices(data)) {
    return <Services data={data} />;
  }

  if (isSanityTestimonials(data)) {
    return <Testimonials data={data} />;
  }

  return <P>Error loading content...</P>;
};

export default HomeContent;
